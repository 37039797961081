#offCanvasLeft {

	background-color: $black;

	a {
		border-top: 2px transparent solid;
		border-bottom: 2px transparent solid;

		color: $white;

		&:hover, &:focus, &:active {
			border-bottom-color: transparent; // because hover, focus, active can not be triggered on mobiles
		}
	}

	.drilldown.menu {
		a {
			background-color: $black;
		}
	}

	.menu.vertical {
		// background: $black;
	}
}

.off-canvas-content {
	min-height: 70vh;
}

.off-canvas {
	.logo {
		background-image: url("/assets/img/logos/cc-140pt-inv_w420.png");
		background-position: 1rem .7rem;
		background-repeat:  no-repeat;
		background-size: auto 1rem;
		display: block;
		color: $white;
		text-indent: -9999em;
		// height: 2.5rem;
	}
}
