
.top-bar-wrapper {
	background: darken($black, 5%);
	&>.grid-container {
		// padding: 0;
	}
}

.top-bar {
	// background: transparent;
	padding: 0;

	.menu {
		li {
			a {
				padding-top: 1.0rem;
				padding-bottom: 1.0rem;
			}
		}

		li.is-active {
			a {
				color: $light-gray; // $secondary-color;
				&:hover {
					color: $primary-color;
				}
			}
		}
	}

	.is-dropdown-submenu {
		border-color: $dark-gray;
	}

	a {
		// padding-top: 1.5rem;
		// padding-bottom: 1.5rem;
		
		color: $light-gray; // $secondary-color;

		&:hover, &:focus, &:active {
			color: $primary-color;
			border-bottom-color: $primary-color;
		}
	}



	.logo {
		background-image: url("/assets/img/logos/cc-140pt-inv_w420.png");
		background-position: 1rem 1rem;
		background-repeat:  no-repeat;
		background-size: auto 1rem;
		display: inline-block;
		width: 9.5rem;
		color: $secondary-color;	
		text-indent: -9999em;
	}

	.fi {
		font-size: 1rem;
		vertical-align: bottom;
	}


}