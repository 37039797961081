#main {
	padding: 0;
}

#page-footer {
	color: $white;
	font-size: 1rem;

	display: sticky;
	bottom: 0;

	h1, h2, h3, h4, h5, h6 {
		font-size: 1rem/16*20;
		color: $white;

		i.fi {
			font-size: 1rem/16*24;
			vertical-align: bottom;
			display: inline-block;
			width: 1rem/16*24;
		}
	}

	i.fi {
		font-size: 1rem/16*24;
		vertical-align: -.2rem;
		display: inline-block;
		line-height: 1em;
		width: 1rem/16*24;
	}


	a {
		color: $white;
		display: inline-block;
		padding: .5rem 0 0; // increase clickable area of the footer links

		text-decoration: none;


		border-top: 2px transparent solid;
		border-bottom: 2px transparent solid;

		&:hover, &:focus, &:active {
			border-bottom-color: $white;
		}

	}

	.footer-section-1 {
		background-color: darken($black, 5%);
	}

	.footer-section-2 {
		background-color: darken($black, 10%);

		// a {
		// 	&:hover, &:focus, &:active {
		// 		color: $secondary-color;
		// 		border-bottom-color: $secondary-color;
		// 	}
		// }

		.inline-list {
			li {
				display: inline-block;
				padding-right: 2em;
			}
		}
	}
}
