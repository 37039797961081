.reference {
	// &>header {
	// 	// text-shadow: 1px 1px 1px $black;
	// 	background-position: center;
	// 	background-repeat: no-repeat;
	// 	background-size: cover;
	// }
	// &>section {
	// 	padding-top: 2rem;
	// 	padding-bottom: 4rem;
	// }
	// margin: 2rem 0 2rem;
	padding: 2.5rem 0 0;
	background-color: darken($black, 5%);;
}

.reference-image-list {
	list-style-type: none;
	margin-bottom: 0;
}


.reference-information {

	$border-color: $medium-gray;
	$border-width: 2px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	background: $black; //transparent $light-gray;
	color: white;
	// border-top: $border-width solid $border-color;
	// border-left: $border-width solid $border-color;
	// border-bottom: $border-width solid $border-color;
	// border-right: $border-width solid $border-color;

	&>ul {
		margin: 0;
		padding: 15px;
		
		@include breakpoint(medium) {
			padding: .5em 2em;
		}

		li {
			line-height: 1.7em;
		}
	}

}

.reference-title {
	font-size:1.2em;
}