.section {
	@include section;
}

.dark {
	@include dark;
}

.text-block {
	@include text-block;
	padding-right: 2em;
	padding-left: 2em;

}

.dyslexic .text-block {
	max-width: 38em;
}

.bg-image-cover {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	min-height: 240px;
}
