.menu {

	a {
		border-top: 3px transparent solid;
		border-bottom: 3px transparent solid;

		&:hover, &:focus, &:active {
			border-bottom-color: $anchor-color;
		}
	}
}