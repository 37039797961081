@font-face {
  font-display: swap;
  font-family: 'Nexa-Bold';
  src: url('../fonts/webfont-nexa-bold/Nexa-Bold.eot');
  src: url('../fonts/webfont-nexa-bold/Nexa-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/webfont-nexa-bold/Nexa-Bold.svg#Nexa-Bold') format('svg'),
       url('../fonts/webfont-nexa-bold/Nexa-Bold.ttf') format('truetype'),
       url('../fonts/webfont-nexa-bold/Nexa-Bold.woff') format('woff'),
       url('../fonts/webfont-nexa-bold/Nexa-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

/* noto-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/noto-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-sans-v27-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}

/* noto-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/noto-sans-v27-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/noto-sans-v27-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-sans-v27-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans-v27-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans-v27-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-sans-v27-latin-italic.svg#NotoSans') format('svg'); /* Legacy iOS */
}

/* noto-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/noto-sans-v27-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../fonts/noto-sans-v27-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-sans-v27-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans-v27-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans-v27-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-sans-v27-latin-600.svg#NotoSans') format('svg'); /* Legacy iOS */
}


/* source-sans-pro-v21-latin/source-sans-pro-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-v21-latin/source-sans-pro-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-v21-latin/source-sans-pro-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.eot'); /* IE9 Compat Modes */
  src: url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin/source-sans-pro-v21-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* noto-sans-v27-latin/noto-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}


// @font-face {
//   font-family: 'MPLUSRounded1c-Bold';
//   src: url('../fonts/webfont-rounded-m-plus-1c-bold/MPLUSRounded1c-Bold.eot');
//   src: url('../fonts/webfont-rounded-m-plus-1c-bold/MPLUSRounded1c-Bold.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/webfont-rounded-m-plus-1c-bold/MPLUSRounded1c-Bold.woff2') format('woff2');
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'RoundedMplus1c-Bold';
//   src: url('../fonts/webfont-rounded-m-plus-1c-bold/RoundedMplus1c-Bold.svg#RoundedMplus1c-Bold') format('svg'),
//        url('../fonts/webfont-rounded-m-plus-1c-bold/RoundedMplus1c-Bold.ttf') format('truetype'),
//        url('../fonts/webfont-rounded-m-plus-1c-bold/RoundedMplus1c-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
// }

@font-face {
  font-family: 'OpenDyslexic';
  src: url('../fonts/open-dyslexic/svg/OpenDyslexic-Regular.svg') format('svg'),
  url('../fonts/open-dyslexic/ttf/OpenDyslexic-Regular.ttf') format('truetype'),
  url('../fonts/open-dyslexic/woff/OpenDyslexic-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


html {
  font-size: $global-font-size*(16/20);

  @include breakpoint(xlarge) {
    font-size: $global-font-size*(20/20);
  }
  // @include breakpoint(landscape xlarge) {
  //   font-size: $global-font-size;
  // }

  // text-align: justify;
  hyphens: auto;
}

a {
	text-decoration: underline;
	text-decoration-style: dotted;

	&:hover, &:focus, &:active {
		text-decoration-style: solid;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Nexa-bold";
  margin-top: .7em;
  margin-bottom: .5em;
	// text-transform: lowercase;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: .8;
}



.orange-text {
  color: $primary-color;
}

p {
  margin-top: .5em;
}

a[data-tooltip] {
  border-bottom: none;
}

html.dyslexic {
  body {
    font-family: 'OpenDyslexic';
    font-size: 90%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'OpenDyslexic';
    font-weight: normal;
  }

  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'OpenDyslexic';
    font-weight: normal;
  }
}