@mixin section {

	@include breakpoint(medium) {
		// padding: 2em 0 0.5em;
	}

}

@mixin dark {
	background-color: $black;
	color: $white;
}

@mixin text-block {
	max-width: 33em;
	margin: 0 auto;
}

