

.title-bar {
	.menu-icon {
		color: $white;
		text-indent: -9999em;
	}

	.logo {
		background-image: url("/assets/img/logos/cc-140pt-inv_w420.png");
		background-position: left center;
		background-repeat:  no-repeat;
		background-size: auto 1rem;
		display: inline-block;
		width: 7.5rem;
		color: $white;
		text-indent: -9999em;
	}

}
